import T from 'i18n-react'
import { setCookie, getCookie } from './cookies'
import axios from 'axios'

let frText = {};
let enText = {};

export function useTexts(fr: any, en: any) {
    frText = fr;
    enText = en;
}

export function setLanguage(lang: string) {
    currentLanguage = lang;

    if (langFromCookie != lang) {
        setCookie('lang', lang, 36500);
    }
    if (lang == 'fr') {
        T.setTexts(frText, { MDFlavor: 0 });
        axios.defaults.headers.common['Accept-Language'] = 'fr';
    } else if (lang == 'en') {
        T.setTexts(enText, { MDFlavor: 0 });
        axios.defaults.headers.common['Accept-Language'] = 'en';
    } else {
        window.location.href = '/404';
    }
}

export async function setLanguageReq(lang: string) {
    try {
        await axios.post('/set_language', {
            lang: lang
        });
    } catch(err) {
        // TODO: handle error
    }
}

const langFromCookie = getCookie('lang');
export let defaultLanguage = 'fr';
export let currentLanguage = 'fr';

if (langFromCookie) {
    defaultLanguage = langFromCookie;
} else {
    defaultLanguage = window.navigator.language.match(/en/g) === null ? 'fr' : 'en';
}

export default T;