import axios from 'axios'
import * as React from 'react'
import { setToken } from '../common/authentication'
import Divider from '@material-ui/core/Divider'
import { RecoverDTO, LoginDTO } from '../../../shared/types/authentication'
import { ChangePasswordDTO } from '../../../shared/types/user'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/styles'
import theme from '../common/theme'
import { getCookie, setCookie } from '../common/cookies'
import T, { currentLanguage } from '../common/i18n'
import { randAlphaNumStr } from '../common/misc'
import { Route, BrowserRouter } from 'react-router-dom'
import '../images/loading.gif'
import Recaptcha from 'react-google-invisible-recaptcha'

interface Props {
    classes: any,
}
interface State {
    username: string,
    password: string,
    rememberMe: boolean,
    recoverMessage: number, // 0 no message, 1 recover success, 2 recover fail
    recoverChangeMessage: number,
    newPassword: string,
    newPasswordConfirm: string,
    loginError: boolean,
    buttonDisabled: boolean,
    newAccountPassword: string,
    newAccountPasswordConfirm: string,
    newAccountPasswordError: boolean,
    newAccountPasswordError2: boolean,
    newAccountPasswordError3: boolean,
}

const styles = {
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column' as 'column'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    recoverMessage: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    fourofour: {
        fontSize: 240,
        fontWeight: 300
    },
    fourofourText: {
        color: '#666',
        margin: theme.spacing(15, 0, 0, 0)
    },
    fourofourLink: {
        width: '100%',
        margin: theme.spacing(5, 0, 0, 0)
    },
    main: {
        minWidth: 896
    }
};

class Login extends React.Component<Props, State> {

    classes: any;

    recaptcha: any;
    recaptchaAction: string;
    getParams: any = {};
    newAccountCode: any;

    constructor(props: Props) {
        super(props);

        const rememberMeId = getCookie('rememberMeId');

        this.state = {
            username: '',
            password: '',
            rememberMe: rememberMeId === null || rememberMeId != '0', // 0 means the user unchecked rememberMe before
            recoverMessage: 0,
            recoverChangeMessage: 0,
            newPassword: '',
            newPasswordConfirm: '',
            loginError: false,
            buttonDisabled: false,
            newAccountPassword: '',
            newAccountPasswordConfirm: '',
            newAccountPasswordError: false,
            newAccountPasswordError2: false,
            newAccountPasswordError3: false,
        }

        this.classes = props.classes;
  
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewPasswordConfirmChange = this.handleNewPasswordConfirmChange.bind(this);
        this.handleRememberMeChange = this.handleRememberMeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitRecover = this.handleSubmitRecover.bind(this);
        this.handleSubmitRecoverChange = this.handleSubmitRecoverChange.bind(this);
        this.handleNewAccountSubmit = this.handleNewAccountSubmit.bind(this);
        this.recaptchaResolved = this.recaptchaResolved.bind(this);
        this.login = this.login.bind(this);
        this.recover = this.recover.bind(this);

        if (location.search) {
            location.search.substr(1).split(`&`).forEach(item => {let [k,v] = item.split(`=`); v = v && decodeURIComponent(v); (this.getParams[k] = this.getParams[k] || []).push(v)})
        }
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].setAttribute('style', '');
    }
  
    async handleSubmit(event: any) {
        event.preventDefault();

        this.recaptchaAction = 'login';
        if (process.env.NODE_ENV == 'development') {
            this.login();
        } else {
            this.recaptcha.execute();
        }
    }

    async handleSubmitRecover(event: any) {
        event.preventDefault();

        this.recaptchaAction = 'recover';
        if (process.env.NODE_ENV == 'development') {
            this.recover();
        } else {
            this.recaptcha.execute();
        }
    }

    async handleNewAccountSubmit(event: any) {
        event.preventDefault();

        this.recaptchaAction = 'newaccount';
        if (process.env.NODE_ENV == 'development') {
            this.newAccount();
        } else {
            this.recaptcha.execute();
        }
    }

    handleUsernameChange(event: any) {
        this.setState({
            username: event.target.value
        });
    }

    handleChange(event: any) {
        this.setState({
            username: event.target.value
        });
    }

    handlePasswordChange(event: any) {
        this.setState({
            password: event.target.value
        });
    }

    handleNewPasswordChange(event: any) {
        this.setState({
            newPassword: event.target.value
        });
    }

    handleNewPasswordConfirmChange(event: any) {
        this.setState({
            newPasswordConfirm: event.target.value
        });
    }

    handleRememberMeChange(event: any) {
        this.setState({
            rememberMe: event.target.checked
        });
    }

    async handleSubmitRecoverChange(event: any, uuid: string) {
        event.preventDefault();

        if (!this.checkNewPwError()) {
            this.setState({buttonDisabled: true});

            const res = await axios.post('/login_recover_change', { 
                uuid: uuid,
                password: this.state.newPassword
            });
    
            const recoverChange: RecoverDTO = res.data;
    
            if (recoverChange.success) {
                this.setState({recoverChangeMessage: 1});
            } else {
                this.setState({recoverChangeMessage: 2});
            }
            this.setState({buttonDisabled: false});
        }
    }

    checkNewPwError() {
        return (this.state.newPasswordConfirm != '' && this.state.newPassword != this.state.newPasswordConfirm) || 
            (this.state.newPasswordConfirm != '' && this.state.newPassword.length < 8);
    }

    async recover() {
        this.setState({buttonDisabled: true});
        const res = await axios.post('/login_recover', { 
            email: this.state.username,
            captchaToken: this.recaptcha.getResponse()
        });

        const recover: RecoverDTO = res.data;

        if (recover.success) {
            this.setState({recoverMessage: 1});
        } else {
            this.setState({recoverMessage: 2});
        }
        this.setState({buttonDisabled: false});
    }

    async login() {
        let rememberMeId;
        let rememberMeToken;

        if (this.state.rememberMe) {
            rememberMeId = randAlphaNumStr(32);
            rememberMeToken = randAlphaNumStr(32);
        } else {
            rememberMeId = '0';
            rememberMeToken = '';
        }

        this.setState({buttonDisabled: true});
        const res = await axios.post('/login', { 
            username: this.state.username,
            password: this.state.password,
            rememberMeId: rememberMeId == '0' ? '' : rememberMeId,
            rememberMeToken: rememberMeToken,
            captchaToken: this.recaptcha.getResponse()
        });

        const login: LoginDTO = res.data;
        
        if (login.token == '') {
            this.setState({loginError: true, buttonDisabled: false});
            this.recaptcha.reset();
        } else {
            if (this.state.rememberMe) {
                setCookie('rememberMeId', rememberMeId, 36500);
                setCookie('rememberMeToken', rememberMeToken, 36500);
            } else {
                setCookie('rememberMeId', rememberMeId, 36500);
                setCookie('rememberMeToken', rememberMeToken, 0);
            }
            setToken(login.token, login.expiration);

            if ('r' in this.getParams && this.getParams.r.length != 0) {
                window.location.href = this.getParams.r[0];
            } else {
                window.location.href = '/';
            }
        }
    }

    async newAccount() {
        event.preventDefault();

        if (this.state.newAccountPassword == this.state.newAccountPasswordConfirm) {
            if (this.state.newAccountPassword.length < 8) {
                this.setState({newAccountPasswordError2: true, newAccountPasswordError: false, newAccountPasswordError3: false});
            } else {
                this.setState({buttonDisabled: true});

                const resp = await axios.post('/login_new_account', {
                    password: this.state.newAccountPassword,
                    code: this.newAccountCode,
                    captchaToken: this.recaptcha.getResponse()
                });
                const login: LoginDTO = resp.data;

                if (login.token == '') {
                    this.recaptcha.reset();
                    this.setState({newAccountPasswordError: false, newAccountPasswordError2: false, newAccountPasswordError3: false});
                } else if (login.token == 'expired') {
                    this.recaptcha.reset();
                    this.setState({newAccountPasswordError: false, newAccountPasswordError2: false, newAccountPasswordError3: true});
                } else {
                    setToken(login.token, login.expiration);
                    window.location.href = '/';
                }
                this.setState({buttonDisabled: false});
            }
        } else {
            this.setState({newAccountPasswordError: true, newAccountPasswordError2: false, newAccountPasswordError3: false});
        }
    }

    recaptchaResolved() {
        if (this.recaptchaAction == 'login') {
            this.login();
        } else if (this.recaptchaAction == 'recover') {
            this.recover();
        } else if (this.recaptchaAction == 'newaccount') {
            this.newAccount();
        }
    }

    // TODO: tester la version du browser
    // TODO: timeout check si session toujours valide, robot.txt, ajouter captcha, salt password, sign cookie, traduire, limiter width, pw recover, restaurer serveur, erreur 404 si url pas trouvé, i18n différent pour login
    render() {
        return (
            <BrowserRouter>
                <CssBaseline />
                <Route path="/404" exact render={() => (
                    <Container component="main" maxWidth="md" fixed className={this.classes.main}>
                        <div className={this.classes.paper}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h1" color="primary" className={this.classes.fourofour}>404</Typography>
                                </Grid>
                                <Grid item xs={6} className={this.classes.fourofourText}>
                                    <Typography variant="h3">{T.translate('Erreur')}</Typography>
                                    <Typography variant="h4">{T.translate('PageNonTrouvee')}</Typography>
                                </Grid>
                            </Grid>
                            <div className={this.classes.fourofourLink}><Link href="/">» {T.translate('RetourPageAccueuil')} «</Link></div>
                        </div>
                    </Container>
                )} />
                <Route path="/login" exact render={(props) => (
                    <Container component="main" maxWidth="xs">
                        <div className={this.classes.paper}>
                            <Avatar className={this.classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {T.translate('Connexion')}
                            </Typography>
                            <form className={this.classes.form} noValidate onSubmit={this.handleSubmit}>
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={T.translate('AdresseEmail')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={this.state.username}
                                error={this.state.loginError}
                                onChange={this.handleUsernameChange}
                                />
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={T.translate('MotDePasse')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                error={this.state.loginError}
                                helperText={this.state.loginError ? T.translate('LoginError') : ''}
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox 
                                    checked={this.state.rememberMe} 
                                    value="remember" 
                                    onChange={this.handleRememberMeChange}
                                    color="primary" 
                                    />
                                }
                                label={T.translate('SeRappeler')}
                                />
                                <Recaptcha
                                locale={currentLanguage}
                                ref={ (ref: any) => this.recaptcha = ref }
                                sitekey={process.env.RECAPTCHA_KEY}
                                onResolved={ this.recaptchaResolved } />
                                <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={this.classes.submit}
                                disabled={this.state.buttonDisabled}
                                >
                                {T.translate('SeConnecter')}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="javascript:;" onClick={() => props.history.push("/login/recover")} variant="body2">
                                            {T.translate('OublierMotDePasse')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Container>
                )} />
                <Route path="/login/recover/:uuid" exact render={(props) => (
                    <Container component="main" maxWidth="xs">
                        <div className={this.classes.paper}>
                            <Avatar className={this.classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {T.translate('Connexion')}
                            </Typography>
                            <form className={this.classes.form} noValidate onSubmit={(event: any) => this.handleSubmitRecoverChange(event, props.match.params.uuid)} hidden={this.state.recoverChangeMessage != 0}>
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={T.translate('MotDePasseNouveau')}
                                type="password"
                                id="password"
                                autoComplete="off"
                                value={this.state.newPassword}
                                onChange={this.handleNewPasswordChange}
                                />
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password-confirm"
                                label={T.translate('MotDePasseNouveauConfirm')}
                                type="password"
                                id="password-confirm"
                                autoComplete="off"
                                value={this.state.newPasswordConfirm}
                                onChange={this.handleNewPasswordConfirmChange}
                                error={
                                    this.checkNewPwError()
                                }
                                helperText={
                                    this.state.newPasswordConfirm != '' && this.state.newPassword != this.state.newPasswordConfirm ? 
                                    T.translate('PwConfirmError') : 
                                    (this.state.newPasswordConfirm != '' && this.state.newPassword.length < 8 ? T.translate('PwConfirmError2') : '')
                                }
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={this.classes.submit}
                                    disabled={this.state.buttonDisabled}
                                >
                                {T.translate('Continuer')}
                                </Button>
                            </form>
                            <div hidden={this.state.recoverChangeMessage != 1} className={this.classes.recoverMessage}>
                                <Typography variant="body2" paragraph>
                                {T.translate('PwRecoverChangeMessage')}  
                                </Typography>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="javascript:;" onClick={() => {this.setState({recoverChangeMessage: 0}); props.history.push("/login")}} variant="body2">
                                            {T.translate('Retour')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </div>
                            <div hidden={this.state.recoverChangeMessage != 2} className={this.classes.recoverMessage}>
                                <Typography variant="body2" paragraph>
                                {T.translate('PwRecoverChangeMessage2')}  
                                </Typography>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="javascript:;" onClick={() => {this.setState({recoverChangeMessage: 0}); props.history.push("/login/recover")}} variant="body2">
                                            {T.translate('Retour')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Container>
                )} />
                <Route path="/login/recover" exact render={(props) => (
                    <Container component="main" maxWidth="xs">
                        <div className={this.classes.paper}>
                            <Avatar className={this.classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {T.translate('Connexion')}
                            </Typography>
                            <form className={this.classes.form} noValidate onSubmit={this.handleSubmitRecover} hidden={this.state.recoverMessage != 0}>
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={T.translate('AdresseEmail2')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                                />
                                <Recaptcha
                                locale={currentLanguage}
                                ref={ (ref: any) => this.recaptcha = ref }
                                sitekey={process.env.RECAPTCHA_KEY}
                                onResolved={ this.recaptchaResolved } />
                                <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={this.classes.submit}
                                disabled={this.state.buttonDisabled}
                                >
                                {T.translate('Continuer')}
                                </Button>
                            </form>
                            <div hidden={this.state.recoverMessage != 1} className={this.classes.recoverMessage}>
                                <Typography variant="body2" paragraph>
                                    {T.translate('PwRecover1')}
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {T.translate('PwRecover2')}
                                </Typography>
                            </div>
                            <div hidden={this.state.recoverMessage != 2} className={this.classes.recoverMessage}>
                                <Typography variant="body2" paragraph>
                                {T.translate('PwRecover3')}  
                                </Typography>
                            </div>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="javascript:;" onClick={() => {this.setState({recoverMessage: 0}); props.history.push("/login")}} variant="body2">
                                        {T.translate('Retour')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                )} />
                <Route path="/login/code/:code" exact render={(props) => (
                    <Container component="main" maxWidth="xs">
                        <div className={this.classes.paper}>
                            <div className={this.classes.topText}>
                                <Typography variant="h6" color="textSecondary">{T.translate('Bienvenue')}</Typography>
                                <Typography variant="body1" color="textSecondary">{T.translate('Bienvenue2')}</Typography>
                            </div>
                            <Divider className={this.classes.topDivider} />
                            <form noValidate autoComplete="off" className={this.classes.form} onSubmit={(event: any) => { this.newAccountCode = props.match.params.code, this.handleNewAccountSubmit(event); } }>
                                <TextField
                                    id="password"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label={T.translate('NouveauMotDePasse').toString()}
                                    fullWidth
                                    type="password"
                                    autoComplete="off"
                                    value={this.state.newAccountPassword}
                                    onChange={(e) => this.setState({newAccountPassword: e.target.value}) }
                                    error={this.state.newAccountPasswordError2 || this.state.newAccountPasswordError3}
                                    helperText={this.state.newAccountPasswordError3 ? T.translate('PasswordError3') : (this.state.newAccountPasswordError2 ? T.translate('PasswordError2') : '')}
                                />
                                <TextField
                                    id="password-confirm"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label={T.translate('NouveauMotDePasse2').toString()}
                                    type="password"
                                    fullWidth
                                    autoComplete="off"
                                    value={this.state.newAccountPasswordConfirm}
                                    onChange={(e) => this.setState({newAccountPasswordConfirm: e.target.value}) }
                                    error={this.state.newAccountPasswordError}
                                    helperText={this.state.newAccountPasswordError ? T.translate('PasswordError') : ''}
                                />
                                <Recaptcha
                                    locale={currentLanguage}
                                    ref={ (ref: any) => this.recaptcha = ref }
                                    sitekey={process.env.RECAPTCHA_KEY}
                                    onResolved={ this.recaptchaResolved } />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    className={this.classes.button}
                                    disabled={this.state.buttonDisabled}
                                    >
                                    {T.translate('Continuer')}
                                </Button>
                            </form>
                        </div>
                    </Container>
                )} />

            </BrowserRouter>
        );
    }
}

export default withStyles(styles)(Login);