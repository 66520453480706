import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#ff4400',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        //light: '#0066ff',
        main: '#eee',
        dark: '#95969b',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      }
      // error: will use the default color
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 575,
        md: 768,
        lg: 991,
        xl: 1199
      }
    }
});
