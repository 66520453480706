import T from './i18n'
import * as mobile from 'react-device-detect'
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';

export function ucfirst(str: string) {
    return str.charAt(0).toLocaleUpperCase('fr-CA') + str.substr(1);
}

export function randAlphaNumStr(len: number) {
    let str = '';
    for (let j = len / 5 + 1; j > 0; j--) {
        str += Math.random().toString(36).substr(2, 5);
    }
    str = str.substr(0, len);
    while (str.length != len) { str += '0' }

    return str;
}

export function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getLocalOffsetedDate(date = new Date()) {
    return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
}

export function removeLocalDateOffset(date = new Date()) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}

export function validateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true);
    }
    return (false);
}

export function printDate(date: string | Date, formatting = 'full') {
    if (date == '' || date == null) {
        return '';
    } else {
        if (typeof(date) == 'string') {
            date = new Date(date);
        }
        if (formatting == 'weekdate') {
            return T.translate('formattedDateWeekOnly', {
                day: date.getDate(),
                weekDay: T.translate(`jour${date.getDay()}`),
                month: T.translate(`month${date.getMonth()}`),
                year: date.getFullYear(),
            }).toString();
        } else if (formatting == 'date') {
            const hoursDiff: any = (new Date().getTime() - date.getTime()) / 1000 / 60 / 60;

            if (hoursDiff < 24) {
                return parseInt(hoursDiff) + 'h';
            }

            return T.translate('formattedDateOnly', {
                day: date.getDate(),
                month: T.translate(`month${date.getMonth()}`),
                year: date.getFullYear(),
            }).toString();
        } else {
            const hours = date.getHours();
            const minutes = date.getMinutes();

            return T.translate('formattedDate', {
                day: date.getDate(),
                weekDay: T.translate(`jour${date.getDay()}`),
                month: T.translate(`month${date.getMonth()}`),
                year: date.getFullYear(),
                hour: hours < 10 ? `0${hours}` : hours,
                minutes: minutes < 10 ? `0${minutes}` : minutes,
            }).toString();
        }
    }
}

export function copyToClipboard(text: string, parent: any = document.body) {
    let textArea: any = document.createElement("textarea");
    
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    
    textArea.value = text;
    
    parent.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        let successful = document.execCommand('copy');
    } catch (err) {
        // TODO: show error
    }
    
    parent.removeChild(textArea);
}

export function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function urlB64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function browserSupported() {
    return !((mobile.isIE && parseInt(mobile.browserVersion)) < 11
        || (mobile.isEdge && parseInt(mobile.browserVersion)) < 14
        || (mobile.isFirefox && parseInt(mobile.browserVersion)) < 52
        || ((mobile.isChrome || mobile.isChromium) && parseInt(mobile.browserVersion)) < 49
        || (mobile.isSafari && parseInt(mobile.browserVersion)) < 10)
}