import * as ReactDOM from 'react-dom'
import * as React from 'react'
import Login from './components/Login'
import './common/axiosDefaultsLogin'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './common/theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import { setLanguage, defaultLanguage, useTexts } from './common/i18n'
import { fr, en } from './common/i18nTextLogin'
import { browserSupported } from './common/misc'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/500-italic.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/700-italic.css'

useTexts(fr, en);
setLanguage(defaultLanguage);

if (!browserSupported) {
    window.location.href = '/old_browser.html';
}

ReactDOM.render(
<MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Login />
</MuiThemeProvider>,
document.getElementById('app')
);