export const fr = {
    PwRecover1: 'Un courriel vous a été envoyé avec les instructions pour réinitialiser votre mot de passe.',
    PwRecover2: 'Si vous n’avez rien reçu, vérifiez votre boîte de courriel indésirable.',
    PwRecover3: 'Aucun compte n’est associé à cette adresse courriel.',
    LoginError: 'La connexion a échouée, votre information est incorrecte.',
    LinkPwChange: '/fr/nouveaucompte',
    Retour: '« Retour',
    Envoyer: 'Envoyer',
    Continuer: 'Continuer',
    RecuperationMotDePasse: 'Récupération de mot de passe',
    AdresseEmail: 'Adresse courriel',
    AdresseEmail2: 'Entrez votre adresse courriel',
    OublierMotDePasse: 'Oublié votre mot de passe?',
    SeRappeler: 'Mémoriser',
    MotDePasse: 'Mot de passe',
    Bienvenue: 'Bienvenue sur l’application de Refmedia',
    Bienvenue2: 'Entrez un mot de passe pour votre compte',
    Connexion: 'Connexion',
    SeConnecter: 'Se Connecter',
    Deconnexion: 'Déconnexion',
    Erreur: 'Erreur.',
    PageNonTrouvee: 'Page non trouvée.',
    RetourPageAccueuil: 'Cliquez ici pour retourner à la page d’accueuil',
    MotDePasseNouveauConfirm: 'Confirmer le nouveau mot de passe',
    MotDePasseNouveau: 'Nouveau mot de passe',
    PwConfirmError: 'Nouveau mot de passe et confirmation doivent être similaires.',
    PwRecoverChangeMessage: 'Votre mot de passe a été changé.',
    PwRecoverChangeMessage2: 'Votre demande est expirée, veuillez recommencer.',
    PwConfirmError2: 'Votre mot de passe doit être au moins 8 caractères.',
    NouveauMotDePasse: 'Nouveau mot de passe',
    NouveauMotDePasse2: 'Confirmer le nouveau mot de passe',
    PasswordError: 'Mot de passe et confirmation du mot de passe doivent être similaires.',
    PasswordError2: 'Mot de passe doit contenir au minimum 8 caractères.',
    PasswordError3: 'Votre invitation est expirée.',
};

export const en = {
    PwRecover1: 'An email was sent to your address with instructions on how to reset your password.',
    PwRecover2: 'If you didn’t receive it, check your junk mail.',
    PwRecover3: 'No account is associated with this email address.',
    LoginError: 'The login has failed, your information is incorrect.',
    PasswordError: 'Password and password confirmation must be the same.',
    PasswordError2: 'Your password must contain at least 8 characters.',
    LinkPwChange: '/en/newaccount',
    Retour: '« Back',
    Envoyer: 'Send',
    Continuer: 'Continue',
    SeConnecter: 'Login',
    RecuperationMotDePasse: 'Password recovery',
    AdresseEmail: 'Email address',
    AdresseEmail2: 'Enter your email address',
    OublierMotDePasse: 'Forgot your password?',
    Bienvenue: 'Welcome to the Référence Média application',
    Bienvenue2: 'Please enter your password for your account',
    SeRappeler: 'Remember me',
    MotDePasse: 'Password',
    Connexion: 'Sign in',
    Deconnexion: 'Logout',
    Erreur: 'Error.',
    PageNonTrouvee: 'Page not found.',
    RetourPageAccueuil: 'Click here to go back to the home page',
    MotDePasseNouveauConfirm: 'Confirm new password',
    MotDePasseNouveau: 'New password',
    NouveauMotDePasse: 'New password',
    NouveauMotDePasse2: 'Confirm new password',
    PwConfirmError: 'New password and confirmation must be the same.',
    PwRecoverChangeMessage: 'Your password has been changed.',
    PwRecoverChangeMessage2: 'Your request has expired, please start the process again.',
    PwConfirmError2: 'Your password must be at least 8 characters.',
    PasswordError3: 'Your invitation has expired.',
};