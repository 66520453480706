import axios from 'axios'

axios.defaults.baseURL = process.env.API_URL || 'http://localhost:3000';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let abord = false;

axios.interceptors.response.use(function(response) {
    return response;
}, function (error) {
    if (!abord) {
        if (error.response === undefined) {
            window.location.href = '/404';
            abord = true;
        } else if (error.response.status == 403) {
            window.location.href = '/login';
            abord = true;
        } else {
            window.location.href = '/404';
            abord = true;
        }
    }
});